<template>
  <a-spin :spinning="!information || loading || loadingGeoData">
    <div v-if="information" class="">
      <div class="font-size-36 font-weight-bold text-dark mb-4">
        Business Information
      </div>

      <div>
        <a-form class="mb-4" :form="form" @submit="handleSubmit">
          <div class="row">
            <!-- business name -->
            <div class="col-md-6">
              <a-form-item
                id="name"
                :label="`${accountIsTourSight ? 'Site' : 'Business'} Name`"
              >
                <a-input
                  size="large"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: `Please enter a valid ${
                            this.accountIsTourSight ? 'site' : 'business'
                          } name`,
                        },
                      ],
                    },
                  ]"
                  placeholder="Site Name"
                  :disabled="!canUpdateProfile"
                />
              </a-form-item>
            </div>

            <div class="col-md-6">
              <a-form-item
                id="email"
                :label="`${accountIsTourSight ? 'Site' : 'Business'} Email`"
              >
                <a-input
                  size="large"
                  placeholder="Email Address"
                  :disabled="!canUpdateProfile"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message: `Please enter a valid ${
                            this.accountIsTourSight ? 'site' : 'business'
                          } email`,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <facility-phone-number-form-item
                :showLabel="true"
                :disabled="!canUpdateProfile"
              />
            </div>

            <div class="col-md-6">
              <a-form-item label="License number">
                <a-input
                  placeholder="Please enter your License number"
                  v-decorator="[
                    'tourism_authority_number',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Business License number is required!',
                        },
                      ],
                    },
                  ]"
                  :disabled="!canUpdateProfile"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <facility-category-form-item :disabled="!canUpdateProfile" />
            </div>

            <div class="col-md-6">
              <a-form-item label="Ghana Post GPS">
                <a-input
                  placeholder="Please enter your Ghana Post GPS"
                  v-decorator="[
                    'ghana_post_gps_address',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Ghana Post GPS is required!',
                        },
                      ],
                    },
                  ]"
                  :disabled="!canUpdateProfile"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <a-form-item id="youtube_video_link" label="Youtube Video Link">
                <a-input
                  size="large"
                  v-decorator="[
                    'youtube_video_link',
                    {
                      rules: [],
                    },
                  ]"
                  placeholder="https://youtube.come/zdgasdgaxxxadsg"
                  :disabled="!canUpdateProfile"
                />
              </a-form-item>
            </div>

            <div class="col-md-6">
              <a-form-item id="website-url" label="Website Url">
                <a-input
                  size="large"
                  v-decorator="[
                    'website_url',
                    {
                      rules: [],
                    },
                  ]"
                  placeholder="https://www.visitghana.com"
                  :disabled="!canUpdateProfile"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a-form-item
                :label="`${
                  accountIsTourSight ? 'Site' : 'Business'
                } Description`"
                id="description"
              >
                <a-textarea
                  placeholder="Description"
                  :rows="10"
                  :disabled="!canUpdateProfile"
                  v-decorator="[
                    'description',
                    {
                      rules: [],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a-form-item label="General Policy" id="general-policy">
                <a-textarea
                  placeholder="General Policy"
                  :rows="10"
                  :disabled="!canUpdateProfile"
                  v-decorator="[
                    'general_policy',
                    {
                      rules: [],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a-form-item label="Refund Policy" id="refund-policy">
                <a-textarea
                  placeholder="Refund Policy"
                  :rows="10"
                  :disabled="!canUpdateProfile"
                  v-decorator="[
                    'refund_policy',
                    {
                      rules: [],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a-form-item label="Cancellation Policy" id="cancellation-policy">
                <a-textarea
                  placeholder="Cancellation Policy"
                  :rows="10"
                  :disabled="!canUpdateProfile"
                  v-decorator="[
                    'cancellation_policy',
                    {
                      rules: [],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>

          <!-- address -->
          <facility-address-form-item
            :locationData="getValueFromSource(information, 'location')"
            :disabled="!canUpdateProfile"
          />

          <!-- SUBMIT BTN -->
          <a-button
            type="primary"
            htmlType="submit"
            size="large"
            class="text-center mt-4"
            :loading="loading"
            v-if="canUpdateProfile"
          >
            <strong>Update Profile</strong>
          </a-button>
        </a-form>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapGetters } from "vuex";
import AddressMixin from "@/mixins/address";
import ProfileMixin from "@/mixins/Profile";

import FacilityPhoneNumberFormItem from "@/components/facilities/FacilityPhoneNumberFormItem";
import FacilityCategoryFormItem from "@/components/facilities/FacilityCategoryFormItem";
import FacilityAddressFormItem from "@/components/facilities/FacilityAddressFormItem";
import { coercePhone } from "@/helpers";

export default {
  mixins: [AddressMixin, ProfileMixin],
  components: {
    FacilityPhoneNumberFormItem,
    FacilityCategoryFormItem,
    FacilityAddressFormItem,
  },
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this),
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "userMenus"]),
    information() {
      return this.getValueFromSource(this.account, "information");
    },
    permissions() {
      return this.getValueFromSource(this.currentUser, "permissions", {});
    },
    canUpdateProfile() {
      return this.getValueFromSource(
        this.permissions,
        "can_update_business_information",
        false
      );
    },
    initialPhone() {
      const phone = this.getValueFromSource(this.information, "phones[0]");
      if (phone) {
        return {
          phones: phone.number,
          prefix: phone.code,
        };
      }
      return "";
    },
    initialAddress() {
      return {
        city: this.getValueFromSource(this.information, "address.city.geo_id"),
        state: this.getValueFromSource(
          this.information,
          "address.state.geo_id"
        ),
        // country: this.getValueFromSource(
        //   this.information,
        //   "address.country.geo_id"
        // ),
      };
    },
  },
  async created() {
    try {
      this.loading = true;

      const address = this.getValueFromSource(this.information, "address");
      if (address) {
        await this.initializeGeoData(address);

        this.form.setFieldsValue({
          name: this.getValueFromSource(this.information, "name"),
          email: this.getValueFromSource(this.information, "email"),
          description: this.getValueFromSource(this.information, "description"),
          phones: this.getValueFromSource(this.information, "phones[0].number"),
          prefixPhone: this.getValueFromSource(
            this.information,
            "phones[0].code"
          ),
          tourism_authority_number: this.getValueFromSource(
            this.information,
            "tourism_authority_number"
          ),
          entity_type: this.getValueFromSource(this.information, "entity_type"),
          ghana_post_gps_address: this.getValueFromSource(
            this.information,
            "ghana_post_gps_address"
          ),
          youtube_video_link: this.getValueFromSource(
            this.information,
            "youtube_video_link"
          ),
          website_url: this.getValueFromSource(this.information, "website_url"),
          general_policy: this.getValueFromSource(
            this.information,
            "general_policy"
          ),
          refund_policy: this.getValueFromSource(
            this.information,
            "refund_policy"
          ),
          cancellation_policy: this.getValueFromSource(
            this.information,
            "cancellation_policy"
          ),
          address: this.initialAddress,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.updateBusinessInfo(values);
        } else {
          this.$notification.error({
            message:
              "Form validation failed. Please correct errors in form before submiting",
          });
        }
      });
    },
    async updateBusinessInfo(values) {
      try {
        this.loading = true;

        const {
          address: { city, state, country },
          phones,
          prefixPhone,
          ...information
        } = values;

        const address = this.coerceAddressValue({
          city,
          state,
          country,
        });

        information.address = address;
        information.location = this.information.location;
        information.phones = [coercePhone(phones, prefixPhone)];

        await this.updateProfile({
          manager: information,
        });

        this.$notification.success({
          message: "Profile updated successfully",
        });
      } catch (error) {
        this.$notification.error({
          message: "Error saving profile",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
