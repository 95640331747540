/**
 * Address is going to be something that profiles may
 * be editing over and over again
 * 
 * that thing about coercing address for instance is in about
 * three files so far. We do not want more duplicates
 */

const GHANA_GEO_ID = 2300660

import { mapState, mapGetters, mapActions } from "vuex"
import {
  MISC_GET_GEO_DATA,
  MISC_GET_COUNTRIES
} from "@/store/actions"

export default {
  created() {
    this.getCountries();
  },
  data() {
    return {
      geoDataInitialized: false,
    }
  },
  computed: {
    ...mapState('misc', ['loadingGeoData']),
    ...mapGetters('misc', ['getCountriesList', 'getGeoDataStates', 'getGeoDataCities']),
  },
  methods: {
    ...mapActions('misc', {
      getGeoData: MISC_GET_GEO_DATA,
      getCountries: MISC_GET_COUNTRIES,
    }),
    coerceAddressValue(countryStateCity) {
      try {
        const countryStructure = (address) => {
          return {
            geo_id: address.geo_name_id,
            latitude: address.latitude,
            longitude: address.longitude,
            name: address.country,
            iso_code: address.iso_code,
          }
        }
        const stateAndCityStructure = (address) => {
          return {
            geo_id: address.geonameId,
            latitude: address.lat,
            longitude: address.lng,
            name: address.name,
          }
        }
        if (countryStateCity.state) {
          // eslint-disable-next-line
          const data = this.getGeoDataStates.find(state => countryStateCity.state == state.geonameId)
          countryStateCity.state = stateAndCityStructure(data)
        }
        if (countryStateCity.country) {
          // eslint-disable-next-line
          const data = this.getCountriesList.find(country => countryStateCity.country == country.geo_name_id)
          countryStateCity.country = countryStructure(data)
        }
        if (countryStateCity.city) {
          const data = this.getGeoDataCities.find(city => countryStateCity.city === city.geonameId)
          countryStateCity.city = stateAndCityStructure(data)
        }
        return countryStateCity
      } catch (e) {
        console.log(e)
      }
    },
    async handleStateChange(geoId, form) {
      if (this.geoDataInitialized) {
        await this.getStateCities(geoId)
        const city = this.getGeoDataCities[0].geonameId
        form.setFieldsValue({ city })
      }
    },
    async handleCountryChange(geoId, form) {
      if (this.geoDataInitialized) {
        await this.getCountryStates(geoId)
        const state = this.getGeoDataStates[0].geonameId
        form.setFieldsValue({ state })
        this.handleStateChange(state, form)
      }
    },
    async initializeGeoData(address) {
      await this.getCountries();
      if (address && address.country && address.country.geo_id) {
        await this.getCountryStates(address.country.geo_id)
      }
      if (address && address.state && address.state.geo_id) {
        await this.getStateCities(address.state.geo_id)
      }
      this.geoDataInitialized = true;
    },
    async getCountryStates(geoId) {
      await this.getGeoData({ geoId, type: 'states' })
    },
    async getStateCities(geoId) {
      await this.getGeoData({ geoId, type: 'cities' })
    },
  },
}
