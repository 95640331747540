<template>
  <business-information-form />
</template>

<script>
import BusinessInformationForm from "@/components/facilities/BusinessInformationForm";

export default {
  components: {
    BusinessInformationForm,
  },
};
</script>
